<template>
    <div class="uploadFile-content">
      <el-upload
          class="upload-demo"
          action="/api/Upload/FormUpload"
          :headers="uploadHeaders"
          :before-upload="uploadBefore"
          :on-error="uploadError"
          :on-success="uploadSuccess"
          :on-progress="uploadProgress"
          :on-remove="handleRemove"
          :show-file-list="false"
          :before-remove="beforeRemove"
          name="file"
          multiple
          >
          <div class="positiveID flex_jc_ac" v-if="showType === 'positiveID'">
            <img :src="fileInfo ? fileInfo : require('../../assets/images/frontcard.png')" />
          </div>
          <div class="positiveID flex_jc_ac antiID" v-else-if="showType === 'antiID'">
            <img :src="fileInfo ? fileInfo : require('../../assets/images/reversecard.png')" />
          </div>
          <div class="positiveID flex_jc_ac" v-else-if="showType === 'business'">
            <img :src="fileInfo ? fileInfo : require('../../assets/images/cooh.jpg')" />
          </div>
          <div class="publishProject flex_jc_ac" v-else-if="showType === 'publishProject'">
            <img v-if="fileInfo" :src="fileInfo" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <el-button v-else class="uploadImg" type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button>
      </el-upload>
    </div>
</template>

<script>
export default {
  name: 'uploadImg',
  props: {
    uploadType: {
      type: String,
      default: ''
    },
    showType: { // 判断样式结果
      type: String,
      default: ''
    },
    avatar_url: { // 头像
      type: String,
      default: ''
    }
  },
  mounted() {
    this.fileInfo = this.avatar_url
  },
  data() {
    return {
      fileInfo: '', // 已上传图片
      uploadHeaders: { // 上传图片头部参数
        token: localStorage.getItem('token')
      }
    }
  },
  methods: {
    handleRemove(file, fileList) { // 移除图片
    },
    beforeRemove(file, fileList) { // 移除图片之前
    },
    delUploadFile(index) { // 删除上传图片
      this.fileList.splice(index, 1);
      this.$emit('singleImage', this.fileInfo, this.showType)
    },
    uploadSuccess(file) { // 图片上传成功
      // console.log(file)
      this.fileInfo = file.data
      this.$emit('singleImage', this.fileInfo, this.showType)
    },
    uploadError(file) { // 图片上传失败
      // console.log('file11', file);、
    },
    uploadProgress(file) { // 图片上传进度
    },
    uploadBefore(file) { // 图片状态改变
      // console.log('file', file);
    }
  }
}
</script>

<style lang="scss" scoped>
  .publishProject{
    width: 80px;
    height: 80px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .avatar-uploader-icon{
      color: #666;
      font-size: 20px;
    }
  }
  .antiID{
    margin-left: 60px;
  }
  .positiveID{
    width: 300px;
    height: 180px;
    border: 1px dashed #999;
    border-radius: 6px;
    object-fit: cover;
    img{
      width: 280px;
      object-fit: cover;
      height: 160px;
      border-radius: 6px;
    }
  }
  .uploadImg{
    margin-left: 20px;
  }
</style>
