<template>
  <div class="swiperContainer">
    <div v-if="exhibitImage.length === 0 && !isedit && moneImgType === 0" class="content">暂无内容</div>
    <div v-if="exhibitImage.length && !isedit" class="flex_ac flexWrap">
      <img v-for="item in fileList" class="exhibitImage" :key="item.url" :src="item.url" alt="">
    </div>
    <el-upload
      v-if="isedit"
      action="/api/Upload/FormUpload"
      list-type="picture-card"
      :headers="uploadHeaders"
      name="file"
      :on-success="uploadSuccess"
      :on-change="handleChange"
      :file-list="fileList"
      :auto-upload="true"
      class="upload-demo"
      multiple
      >
        <img class="uploadTop" src="../../assets/images/icon/tupian.png" alt="" />
        <div class="text">上传图片</div>
        <div slot="file" slot-scope="{file}">
          <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <!-- <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span> -->
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UploadMultipleImages',
  props: {
    exhibitImage: { // 父组件返回
      type: Array,
      default: () => []
    },
    isedit: {
      type: Boolean,
      default: false
    },
    moneImgType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      uploadHeaders: { // 上传文件头部参数
        token: localStorage.getItem('token')
      }
    };
  },
  watch: {
    exhibitImage(imgs) {
      this.fileList = imgs
    }
  },
  methods: {
    handleRemove(file, fileList) {
      const list = []
      this.fileList.map((res, index) => {
        if (res.uid === file.uid) {
          this.fileList.splice(index, 1);
          this.fileList.map(res => {
            if (res.response) {
              list.push(res.response.data)
            }
          })
          this.$emit('multipleImages', list.join(','), this.moneImgType)
        }
      })
    },
    beforeRemove(file, fileList) { // 移除文件之前
      return this.$confirm(`确定移除 ${file.name} ？`);
    },
    uploadSuccess(file) { // 图片上传成功
      // console.log(this.fileList)
      const list = []
      this.fileList.map(res => {
        if (res.response) {
          list.push(res.response.data)
        }
      })
      this.$emit('multipleImages', list.join(','), this.moneImgType)
      // console.log(list.join(','))
      // this.uploadImgList = [...this.uploadImgList, ...file.data];
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-5);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
    },
    uploadBefore(file) { // 图片状态改变
    },
    uploadError(file) { // 图片上传失败
    },
    uploadProgress(file) { // 图片上传进度
    }
  }
}
</script>
<style lang="scss" scoped>
  .swiperContainer{
    margin-top: 16px;
    .flexWrap{
       flex-wrap: wrap;
    }
    .exhibitImage:nth-child(3){
      margin-right: 0;
    }
    .exhibitImage{
      width: 272px;
      height: 188px;
      margin-bottom: 18px;
      margin-right: 18px;
      object-fit: cover;
    }
    .content{
        padding: 15px 0 0 15px;
        font-size: 14px;
        min-height: 130px;
        color: #333;
      }
    /deep/{
      .el-upload--picture-card{
        line-height: 1;
        width: 200px;
        height: 130px;
      }
      .el-upload-list--picture-card .el-upload-list__item{
        width: 200px;
        height: 130px;
      }
    }
    .uploadTop{
      margin-top: 37px;
    }
    .text{
      color: #999;
      font-size: 14px;
      margin-top: 5px;
    }
  }
</style>
