<template>
  <div class="certification" v-if="isCertification">
    <div class="perfect"><span @click="backCertification">基本信息</span> > 实名认证</div>
    <div class="skill">
      <h4>请选择您要入驻的分类</h4>
      <ul class="flex">
        <li :class="skillChoiceList.indexOf(item) != '-1' ? 'skillChoice' : ''" @click="choiceSkill(item)" v-for="(item, index) in editSkillList"
        :key="index">{{ item.name }}</li>
      </ul>
    </div>
    <div class="idCcard">
      <h4>{{choose === 1 ? '请上传身份证照片' : '请上传营业执照'}}</h4>
      <div class="flex_jc_ac" v-if="choose === 1">
        <div class="cation">
          <UploadImg :showType="'positiveID'" @singleImage="singleImage" />
          <div class="text">身份证人像面</div>
        </div>
        <div class="cation">
          <UploadImg :showType="'antiID'" @singleImage="singleImage" />
          <div class="text textLeft">身份证国徽面</div>
        </div>
      </div>
      <div class="cation" v-if="choose === 2">
          <UploadImg :showType="'business'" @singleImage="singleImage" />
          <div class="text">营业执照</div>
        </div>
    </div>
  </div>
</template>

<script>
import UploadImg from '@/components/upload/uploadImg'
export default {
  name: 'Certification',
  components: {
    UploadImg
  },
  props: {
    choose: { // 判断是个人还是企业
      type: Number,
      default: 0
    },
    isCertification: {
      type: Boolean,
      default: false
    },
    editSkillList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      positiveIDImg: '',
      positionType: '',
      skillChoiceList: [] // 擅长技能选择列表
    }
  },
  methods: {
    singleImage(img, type) {
      // console.log('11', img, type)
      this.positiveIDImg = img
      this.positionType = type
      this.$emit('choiceSkillClick', this.positiveIDImg, this.positionType)
    },
    backCertification() {
      this.$emit('backCertification')
    },
    choiceSkill(name) { // 选择擅长技能
      let idx = this.skillChoiceList.indexOf(name);
      if (idx === -1) {
        if (this.skillChoiceList.length > 2) {
          this.$message.error('分类必选，最多只能选3个');
          return
        }
        this.skillChoiceList.push(name);
      } else {
        this.skillChoiceList.splice(idx, 1);
      }
      this.$emit('choiceSkill', this.skillChoiceList)
      // this.joinCategories = []
      // this.skillChoiceList.map(res => {
      //   this.joinCategories.push(res.id)
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
  .certification{
    padding: 32px 24px;
    .idCcard{
      text-align: center;
      .cation{
        width: 100%;
        text-align: center;
        .text{
          margin-top: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #999999;
        }
        .textLeft{
          margin-left: 60px;
        }
      }
    }
    .perfect{
      font-size: 18px;
      span{
        cursor: pointer;
        &:hover{
          color: #1166CF;
        }
      }
    }
    .skill {
      width: 700px;
      margin: auto;
      text-align: center;
      h4 {
        text-align: center;
        font-size: 18px;
        margin-bottom: 39px;
      }
      ul {
        flex-wrap: wrap;
        margin-bottom: 50px;
        li {
          width: 108px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          text-align: center;
          background: #F6F6F6;
          border-radius: 4px;
          cursor: pointer;
          margin: 0 10px 20px 10px;
        }
        li:hover {
          color: #1166CF;
        }
        .skillChoice {
          background-image: url("../../assets/images/realname.png");
          background-size: 100% 100%;
          color: #1166CF;
        }
        .skillChoice:hover {
          color: #1166CF;
        }
      }
      .buttonclass {
        width: 320px;
        height: 48px;
      }
    }
  }
</style>
