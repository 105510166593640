import request from '@/utils/request'

export function cityList(data) { // 7.城市列表
  return request({
    url: 'City/cityList',
    method: 'post',
    data
  })
}
export function cityLetterListList(data) { // 7.城市列表
  return request({
    url: 'City/letterList',
    method: 'post',
    data
  })
}
export function BannerList(data) { // 8.轮播图列表
  return request({
    url: 'Banner/getList',
    method: 'post',
    data
  })
}
export function ArticleList(data) { // 10.文章列表
  return request({
    url: 'Article/getList',
    method: 'post',
    data
  })
}
export function cateList(data) { // 12.分类列表
  return request({
    url: 'Cate/getList',
    method: 'post',
    data
  })
}
export function elementListList(data) { // 12.分类列表
  return request({
    url: 'Cate/elementList',
    method: 'post',
    data
  })
}
export function ProjectDataList(data) { // 21.项目列表
  return request({
    url: 'Project/getList',
    method: 'post',
    data
  })
}
export function ProjecDetails(data) { // 33.项目详情
  return request({
    url: 'Project/read',
    method: 'post',
    data
  })
}
export function followCreate(data) { // 24.关注接口
  return request({
    url: 'Follow/create',
    method: 'post',
    data
  })
}
export function followCancel(data) { // 25.取消关注
  return request({
    url: '/Follow/cancel',
    method: 'post',
    data
  })
}
export function tenderCancelt(data) { // 27.用户取消投标
  return request({
    url: 'Tender/cancel',
    method: 'post',
    data
  })
}

export function Tender(data) { // 22.投标
  return request({
    url: 'Tender/create',
    method: 'post',
    data
  })
}
export function updateProject(data) { // 23.修改项目
  return request({
    url: 'Project/update',
    method: 'post',
    data
  })
}
export function bannerHome(data) { // 26.主页信息
  return request({
    url: 'Banner/home',
    method: 'post',
    data
  })
}
export function cancelProject(data) { // 28.取消项目
  return request({
    url: 'Project/cancel',
    method: 'post',
    data
  })
}
export function indexTender(data) { // 29.投标列表
  return request({
    url: 'Tender/index',
    method: 'post',
    data
  })
}
export function selection(data) { // 30.选标
  return request({
    url: 'Tender/selection',
    method: 'post',
    data
  })
}
export function finish(data) { // 30.选标
  return request({
    url: 'Project/finish',
    method: 'post',
    data
  })
}
export function evaluCreate(data) { // 32.评价
  return request({
    url: 'Evaluation/create',
    method: 'post',
    data
  })
}
