<template>
  <div class="intrduBox">
    <div class="padding" v-if="!isCertification">
      <el-form :model="connect" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <!-- 基本信息有值 -->
        <div v-if="!isEdit && !complete">
          <div class="flex_jcsb_ac">
            <div class="tracks">基本信息</div>
            <div class="flex_ac">
              <div class="dataBtn flex_jc_ac btn" @click="completeMaterial">
                <img src="@/assets/images/icon/bianji.png" alt="" />
                <span>完善资料</span>
              </div>
              <div class="previewBtn flex_jc_ac btn" @click="preview">
                <img src="@/assets/images/icon/zhuye.png" alt="" />
                <span>预览</span>
              </div>
            </div>
          </div>
          <div class="flex_jcsb_ac">
            <div class="userBox">
              <div class="name">{{basicInfo.nickName}}</div>
              <div class="information">性别：{{basicInfo.sex === 1 ? '男' : basicInfo.sex === 2 ? '女' : '未填'}}
                <span class="left">手机号：{{basicInfo.phone}}</span>
                <span class="left right" v-if="basicInfo.city">城市：{{basicInfo.city.name}}</span>
                </div>
            </div>
            <img class="userImg" :src="connect.avatarUrl ? connect.avatarUrl : require('@/assets/images/touxiang.png')" alt="" />
          </div>
        </div>
        <!-- 基本信息无值 -->
        <div v-if="isEdit && !complete">
          <div class="perfect"><span @click="completeMaterial">基本信息</span> > 完善资料</div>
          <div class="flex_jcsb_ac settleInBox">
            <div class="settleIn" @click="settleInClick(1)">
              <img class="chooseImg" src="@/assets/images/icon/s.png" alt="" v-if="connect.ischoose === 1" />
              <span v-else></span>
              <img class="bg" src="@/assets/images/individualsettled.png" alt="" />
            </div>
            <div class="settleIn" @click="settleInClick(2)">
              <img class="chooseImg" src="@/assets/images/icon/s.png" alt="" v-if="connect.ischoose === 2" />
              <span v-else></span>
              <img class="bg" src="@/assets/images/companysettled.png" alt="" />
            </div>
          </div>
          <div class="title">基本信息</div>
          <el-form-item label="头像：" prop="name" class="avatar">
            <div class="flex_ac">
              <img :src="connect.avatarUrl ? connect.avatarUrl : require('@/assets/images/touxiang.png')" alt="" />
              <UploadImg @singleImage="singleImage" />
            </div>
          </el-form-item>
          <div class="flex_ac basic">
            <el-form-item label="姓名：" prop="name" class="basicName">
              <el-input v-model="connect.fullName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="name" class="basicPhone">
              <el-input v-model="connect.cellPhone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </div>
          <div class="flex_ac basic">
            <el-form-item label="性别：" prop="name" class="basicName" v-if="connect.ischoose === 1">
              <el-select v-model="connect.gender" placeholder="请选择性别">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市：" prop="name" :class="connect.ischoose === 1 ? 'basicPhone' :' cityjili'">
              <el-select v-model="connect.city" clearable placeholder="请选择">
                <el-option
                  v-for="item in cityMembersList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex_ac basic" v-if="connect.ischoose === 2">
            <el-form-item label="企业类型：" prop="name" class="basicName">
              <el-select v-model="connect.company_type" clearable placeholder="请选择">
                <el-option
                  v-for="item in enterpriseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="flex code">
              <div class="text">统一社会信用代码：</div>
              <el-form-item class="codeMin">
                <el-input v-model="connect.society_code" placeholder="请输入统一社会信用代码"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="certificationColor message flex_ac">
            <!-- <img src="@/assets/icon/zhuye.png" alt="" />
            <span>认证</span> -->
            <div class="title">实名认证：</div>
            <div class="certificationCursor">
              <span class="real cursor" v-if="basicInfo.is_certification === 0" @click="certification">未认证</span>
              <span class="real" v-else>{{basicInfo.is_certification === 1 ? '通过' : '未通过'}}</span>
            </div>
          </div>
        </div>
        <!-- 下面是包括 会员等和联系我们 -->
        <div class="message">
          <div class="title">{{connect.ischoose === 1 ? '会员介绍' : '企业介绍'}}</div>
          <el-input
            class="inputText"
            type="textarea"
            :disabled="!isEdit"
            :autosize="{ minRows: 5 }"
            :placeholder="isEdit ? '请输入内容' : ''"
            v-model="connect.introduc"
            :value="isEdit ? '' :connect.introduc"
          />
          <div class="title" v-if="!complete">主营业务</div>
          <el-input
            v-if="!complete"
            class="inputText"
            type="textarea"
            :disabled="!isEdit"
            :autosize="{ minRows: 5 }"
            :placeholder="isEdit ? '请输入内容' : ''"
            v-model="connect.business"
            :value="isEdit ? '' :connect.business"
          />
          <div class="title">{{connect.ischoose === 1 ? '会员展示' : '企业展示'}}<span v-if="!complete">（建议上传图片尺寸为 288*188px ）</span></div>
          <UploadMultipleImages
          :exhibitImage="connect.member_showList"
          :isedit="isEdit"
          :moneImgType="0"
          @multipleImages="multipleImages" />
          <div class="title">{{connect.ischoose === 1 ? '会员荣誉' : '企业荣誉'}}<span v-if="!complete">（建议上传图片尺寸为 288*188px ）</span></div>
          <el-input
            class="inputText"
            type="textarea"
            :disabled="!isEdit"
            :autosize="{ minRows: 5 }"
            :placeholder="isEdit ? '请输入内容' : ''"
            v-model="connect.honor"
            :value="isEdit ? '' :connect.honor"
          />
          <UploadMultipleImages
          :exhibitImage="connect.member_honor_picList"
          :isedit="isEdit"
          :moneImgType="1"
          @multipleImages="multipleImages" />
          <div class="title">联系我们</div>
          <div class="connectBox">
            <div class="connect flex">
              <div class="caption">联系人</div>
              <el-input
              v-model="connect.name"
              :disabled="!isEdit"
              :value="isEdit ? '' : connect.name"
              :placeholder="isEdit ? '请输入内容' : ''" />
            </div>
            <div class="connect flex">
              <div class="caption">联系电话</div>
              <el-input
              v-model="connect.phone"
              :disabled="!isEdit"
              :value="isEdit ? '' : connect.phone"
              :placeholder="isEdit ? '请输入内容' : ''" />
            </div>
            <div class="connect flex">
              <div class="caption">座机</div>
              <el-input
              v-model="connect.landline"
              :disabled="!isEdit"
              :value="isEdit ? '' : connect.landline"
              :placeholder="isEdit ? '请输入内容' : ''" />
            </div>
            <div class="connect flex">
              <div class="caption">E-mail</div>
              <el-input
              v-model="connect.email"
              :disabled="!isEdit"
              :value="isEdit ? '' : connect.email"
              :placeholder="isEdit ? '请输入内容' : ''" />
            </div>
            <div class="connect flex">
              <div class="caption">地址</div>
              <el-input v-model="connect.address"
              :disabled="!isEdit"
              :value="isEdit ? '' : connect.address"
              :placeholder="isEdit ? '请输入内容' : ''" />
            </div>
          </div>
          <!-- <div class="content" v-else>暂无内容</div> -->
        </div>
      </el-form>
    </div>
    <Certification
    @choiceSkill="choiceSkill"
    @choiceSkillClick="choiceSkillClick"
    :isCertification="isCertification"
    :editSkillList="editSkillList"
    :choose="connect.ischoose"
    @backCertification="backCertification" />
    <div class="centered" v-if="isEdit || isCertification">
      <el-button type="primary" class="submitBtn" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import UploadMultipleImages from '@/components/upload/uploadMultipleImages'
import UploadImg from '@/components/upload/uploadImg'
import Certification from '@/views/myTracks/certification'
import {cityList, cateList} from '@/api/index/index'
import {PerfectInfo} from '@/api/user/user'
export default {
  components: {
    UploadMultipleImages,
    UploadImg,
    Certification
  },
  props: {
    complete: { // 判断显示否 基本信息
      type: String,
      default: ''
    },
    basicInfo: { // 基本信息的值
      type: Object,
      default: () => {}
    }
  },
  name: 'Introduction',
  created() {
    // console.log('2121', this.$route.query.notCertified)
    if (this.$route.query.notCertified) {
      this.isCertification = true
      this.settleListModel()
    }
  },
  watch: {
    basicInfo(info) {
      // console.log('fdbgjd', info)
      if (info.certification_type) {
        this.connect.ischoose = info.certification_type
      }
      if (info.member_show || info.member_honor_pic || info.phone || info.sex || info.business || info.society_code || info.company_type) {
        this.connect.gender = info.sex === 2 ? '女' : '男'
        this.connect.phone = info.phone
        this.connect.business = info.business
        this.connect.society_code = info.society_code
        // 1-政府计划/事业单位 2-国营 3-中外合资 4-外资 5-其他
        this.connect.company_type = info.company_type === 1 ? '政府计划/事业单位' : info.company_type === 2 ? '国营' : info.company_type === 3 ? '中外合资' : info.company_type === 4 ? '外资' : '其他'
      }
      if (info.member_show) {
        this.connect.member_showList = info.member_show.split(',').map(item => {
          return {
            url: item
          }
        })
      }
      if (info.member_honor_pic) {
        this.connect.member_honor_picList = info.member_honor_pic.split(',').map(item => {
          return {
            url: item
          }
        })
      }
      if (info.city) {
        // this.connect.city = info.city.name
        this.connect.city = info.city.id
      }
      if (info.nickName) {
        this.connect.name = info.nickName
        this.connect.fullName = info.nickName
      }
      if (info.phone) {
        this.connect.cellPhone = info.phone
      }
      if (info.introduction) {
        this.connect.introduc = info.introduction
      }
      if (info.member_honor) {
        this.connect.honor = info.member_honor
      }
      if (info.contact_name) {
        this.connect.name = info.contact_name
      }
      if (info.contact_phone) {
        this.connect.phone = info.contact_phone
      }
      if (info.contract_landline) {
        this.connect.landline = info.contract_landline
      }
      if (info.contact_email) {
        this.connect.email = info.contact_email
      }
      if (info.contract_address) {
        this.connect.address = info.contract_address
      }
      if (info.avatarUrl) {
        this.connect.avatarUrl = info.avatarUrl
      }
      // if (info.is_info === 0) {
      //   this.isEdit = true
      //   this.$message.warning('请完善资料')
      //   return
      // }
      // if (info.is_certification === 0) {
      //   this.isCertification = true
      //   this.$message.warning('请实名认证')
      // }
    }
  },
  data() {
    return {
      isEdit: false, // 编辑
      isCertification: false, // 去认证
      connect: {
        member_showList: [],
        member_honor_picList: [],
        avatarUrl: '',
        introduc: '暂无内容',
        business: '暂无内容',
        honor: '暂无内容',
        name: '暂无内容',
        phone: '暂无内容',
        landline: '暂无内容', // 座机
        email: '暂无内容',
        address: '暂无内容', // 地址
        city: '', // 城市
        ischoose: 1, // 判断个人还是公司
        gender: '', // 性别
        fullName: '',
        cellPhone: '',
        business_license: '', // 营业执照
        positiveImg: '', // 身份证正面
        antiImg: '', // 身份证反面
        choiceSkillObj: '', // 分类id
        society_code: '', // 信用代码
        company_type: '',
        member_show: '', // 会员展示图片
        member_honor_pic: '' // 会员荣誉图片
      },
      editSkillList: [], // 入驻分类列表
      cityMembersList: [],
      details: '',
      enterpriseList: [
        {
          id: 1,
          name: '政府计划/事业单位'
        },
        {
          id: 2,
          name: '国营'
        },
        {
          id: 3,
          name: '中外合资'
        },
        {
          id: 4,
          name: '外资'
        },
        {
          id: 5,
          name: '其他'
        }
      ]
    }
  },
  mounted() {
    this.regionalMmbers()
  },
  methods: {
    multipleImages(img, type) {
      if (type === 0) {
        this.connect.member_show = img
      } else {
        this.connect.member_honor_pic = img
      }
    },
    preview() { // 跳转预览 /memberInformation
      const id = this.basicInfo.id
      this.$router.push({name: 'memberInformation', query: {id}})
    },
    singleImage(img) { // 上传头像
      this.connect.avatarUrl = img
    },
    choiceSkillClick(img, type) {
      // console.log('cdss', type, img)
      if (type === 'positiveID') {
        this.connect.positiveImg = img
      } else if (type === 'antiID') {
        this.connect.antiImg = img
      } else if (type === 'business') {
        this.connect.business_license = img
      }
    },
    choiceSkill(list) { // 入驻分类选择
      const choicList = []
      list.map(res => {
        // console.log(res.id)
        choicList.push(res.id)
      })
      this.connect.choiceSkillObj = choicList.join(',')
      // console.log('list', choicList)
    },
    getDetail() {
      // this.connect.introduc = res.data.phone
    },
    regionalMmbers() {
      cityList().then(res => {
        this.cityMembersList = res.data
      })
    },
    certification() { // 去认证
      console.log(this.basicInfo.is_info)
      if (this.basicInfo.is_info === 0) {
        this.$message.warning('请先完善信息')
        return
      }
      this.isCertification = true
      this.settleListModel()
    },
    completeMaterial() { // 点击完善资料
      this.isEdit = !this.isEdit
    },
    backCertification() { // 点击实名认证
      this.$router.push('/user/myTracks')
      this.isCertification = !this.isCertification
    },
    settleListModel() { // 获取入驻分类
      const data = {
        pid: 0,
        level: 0
      }
      cateList(data).then(res => {
        this.editSkillList = res.data
      })
    },
    submit() { // 提交
      if (!this.connect.city && this.isEdit) {
        this.$message.warning('请选择城市')
        return
      }
      if (!this.connect.introduc && this.isEdit) {
        this.$message.warning('请填写介绍')
        return
      }
      if (!this.connect.business && this.isEdit) {
        this.$message.warning('请填写主营业务')
        return
      }
      if (this.connect.ischoose === 1) {
        if (!this.connect.choiceSkillObj && this.isCertification) {
          this.$message.warning('请您选择入驻分类')
          return
        }
        if (!this.connect.positiveImg && this.isCertification) {
          this.$message.warning('请上传身份证正面')
          return
        }
        if (!this.connect.antiImg && this.isCertification) {
          this.$message.warning('请上传身份证反面')
          return
        }
      }
      if (this.connect.ischoose === 2) {
        if (!this.connect.choiceSkillObj && this.isCertification) {
          this.$message.warning('请您选择入驻分类')
          return
        }
        if (!this.connect.company_type) {
          this.$message.warning('请选择企业类型')
          return
        }
        if (!this.connect.society_code) {
          this.$message.warning('请填写社会信用代码')
          return
        }
        if (!this.connect.business_license && this.isCertification) {
          this.$message.warning('请上传营业执照')
          return
        }
      }
      if (this.isCertification) {
        const data = {
          cate_id: this.connect.choiceSkillObj, // 分类ID（,逗号分割）
          id_pic_a: this.connect.positiveImg, // 身份证正面
          id_pic_b: this.connect.antiImg, // 身份证反面
          business_license: this.connect.business_license, // 营业执照
          society_code: this.connect.society_code, // 页数统一社会信用代码
          company_type: this.connect.company_type, // 企业类型 1-政府计划/事业单位 2-国营 3-中外合资 4-外资 5-其他
          certification_type: this.connect.ischoose // 个人  企业
        }
        PerfectInfo(data).then(res => {
          if (res.code === 1) {
            this.$message.success('提交成功,请您稍等审核信息')
            this.isEdit = false
            this.isCertification = false
            this.$emit('submitClick')
          } else {
            this.$message.error(res.mes)
          }
        })
        return
      }
      const data = {
        // cate_id: this.connect.choiceSkillObj, // 分类ID（,逗号分割）
        // id_pic_a: this.connect.positiveImg, // 身份证正面
        // id_pic_b: this.connect.antiImg, // 身份证反面
        // business_license: this.connect.business_license, // 营业执照
        society_code: this.connect.society_code, // 页数统一社会信用代码
        company_type: this.connect.company_type, // 企业类型 1-政府计划/事业单位 2-国营 3-中外合资 4-外资 5-其他
        // 区分
        certification_type: this.connect.ischoose, // 个人  企业
        avatarUrl: this.connect.avatarUrl, // 头像
        nickName: this.connect.fullName, // 昵称
        phone: this.connect.cellPhone, // 手机号
        sex: this.connect.gender, // 0未知1男2女
        introduction: this.connect.introduc, // 会员介绍
        business: this.connect.business, // 主营业务
        member_show: this.connect.member_show, // 会员展示图片(,号分割)
        member_honor: this.connect.honor, // 会员荣誉
        member_honor_pic: this.connect.member_honor_pic, // 会员荣誉图片(,号分割)
        contact_name: this.connect.name, // 联系人名字
        contact_phone: this.connect.phone, // 联系人电话
        contract_landline: this.connect.landline, // 联系座机
        contact_email: this.connect.email, // 联系邮箱
        contract_address: this.connect.address, // 联系地址
        city_id: this.connect.city // 城市ID
      }
      // console.log('dara', data)
      PerfectInfo(data).then(res => {
        if (res.code === 1) {
          this.$message.success('提交成功,请您稍等审核信息')
          this.isEdit = false
          this.isCertification = false
          this.$emit('submitClick')
        } else {
          this.$message.error(res.mes)
        }
      })
    },
    settleInClick(type) {
      this.connect.ischoose = type
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/{
    .el-input.is-disabled .el-input__inner{
      color: #333;
      cursor:auto;
      background: #fff;
    }
    .el-table--enable-row-transition .el-table__body td:nth-child(1){
      text-align: center;
    }
    .el-form-item__content{
      margin-left: none !important;
    }
    .el-textarea.is-disabled .el-textarea__inner{
      background: #fff;
      cursor:auto;
      color: #333;
      border: none;
      resize: none;
      font-family: unset;
    }
  }
  .centered{
    text-align: center;
    .submitBtn{
      width: 180px;
      margin: 30px 0;
    }
  }
  .certificationColor{
    // text-align: left;
    // width: 400px;
    color: #333;
    font-size: 16px;
    span{
      color: #1166CF;
    }
    .certificationCursor{
      margin-top: 15px;
    }
  }
  .intrduBox{
    flex: 1;
    background: #fff;
    padding: 0 24px;
    .padding{
      padding: 24px 24px 0 24px;
      .content{
        padding: 15px 0 0 15px;
        font-size: 16px;
      }
      .code{
        flex: 1;
        margin-left: 5px;
        .codeMin{
          flex: 1;
          margin-left: 55px;
        }
        .text{
          margin-top: 10px;
          font-size: 14px;
          color: #606266;
          position: absolute;
        }
      }
      .basic{
        width: 100%;
        .basicName{
          width: 410px;
        }
        .cityjili{
          width: 410px;
        }
        .basicPhone{
          flex: 1;
          margin-left: 60px;
        }
        /deep/{
          .el-select{
            width: 100%;
          }
        }
      }
      .avatar{
        margin-top: 26px;
        img{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .title{
        margin-top: 15px;
        border-left: 4px solid #1166CF;
        padding-left: 12px;
        font-size: 18px;
        span{
          color: #999;
          font-size: 16px;
        }
      }
      .settleInBox{
        width: 400px;
        margin: 32px auto 0;
        .settleIn{
          position: relative;
          width: 180px;
          height: 80px;
          span{
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            top: 28px;
            left: 16px;
          }
          .chooseImg{
            position: absolute;
            z-index: 1;
            top: 28px;
            left: 16px;
          }
          .bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
          }
        }
      }
      .perfect{
        font-size: 18px;
        span{
          cursor: pointer;
          &:hover{
            color: #1166CF;
          }
        }
      }
      .message{
        padding: 15px 0;
        // margin-top: 25px;
        .connectBox{
          margin-top: 26px;
          border: 1px solid #E8E8E8;
          .connect:nth-child(1){
            background: #FAFAFA;
            /deep/{
              .el-input__inner{
                background: #FAFAFA;
              }
            }
          }
          .connect:nth-child(3){
            background: #FAFAFA;
            /deep/{
              .el-input__inner{
                background: #FAFAFA;
              }
            }
          }
          .connect:nth-child(5){
            background: #FAFAFA;
            /deep/{
              .el-input__inner{
                background: #FAFAFA;
              }
            }
          }
          .connect:nth-child(5){
            border-bottom: 0;
          }
          .connect{
            height: 48px;
            line-height: 48px;
            border-bottom: 1px solid #E8E8E8;
            .caption{
              width: 184px;
              text-align: center;
              border-right: 1px solid #E8E8E8;
            }
            /deep/{
              .el-input__inner{
                border: none;
              }
            }
          }
        }
        /deep/{
          .el-textarea__inner{
            // height: 160px;
          }
        }
        .inputText{
          margin-top: 15px;
          font-size: 16px;
          color: #333;
        }
        .substance{
          font-size: 16px;
          color: #666;
          margin-top: 23px;
        }
      }
      .userImg{
          width: 80px;
          object-fit: cover;
          height: 80px;
          border-radius: 50%;
        }
      .userBox{
        margin-top: 34px;
        margin-bottom: 40px;
        .name{
          font-size: 20px;
        }
        .information{
          font-size: 16px;
          color: #666;
          margin-top: 19px;
          .real{
            color: #1166CF;
            cursor: pointer;
          }
          .left{
            margin-left: 31px;
          }
          .right{
            margin-right: 31px;
          }
        }
      }
      .tracks{
        font-size: 18px;
      }
      .btn{
        height: 36px;
        background: #F5F9FF;
        border-radius: 18px;
        color: #1166CF;
        text-align: center;
        line-height: 36px;
        border: 1px solid transparent;
        box-sizing: border-box;
        cursor: pointer;
        &:hover{
          border: 1px solid #1166CF;
        }
        span{
          margin-left: 8px;
        }
      }
      .dataBtn{
        width: 120px;
      }
      .previewBtn{
        width: 94px;
        margin-left: 12px;
      }
    }
  }
</style>
